import React from "react"
import Guide from "../../../components/guide2020"

const IndexPage = ({data}) => (
  <Guide
    Year={"2020"}
    Section={"bil"}
    ProductName={"bilforsikring"}
    Entity={"bil"}
    Attributes={33}
    Stars5={{
      need: "For deg som har en ny bil og vil være aller best sikret",
      description: 'De aller beste bilforsikringene - 5 defaqto stjerner',
      terms: [
        {t: "Maskinskade - alder bil",c: "Må være dekket i 8 år eller mer"},
        {t: "Maskinskade - km bil",c: "Må være 200 000 km eller mer"},
        {t: "Totalskadegaranti - alder bil",c: "Må være dekket i 36 måneder"},
        {t: "Totalskadegaranti - km bil",c: "Må være 100 000 km eller mer"},
        {t: "Personlig løsøre",c: "Må være dekket for 10 000 kr"},
        {t: "Dekker tap og skade på nøkkel",c: "Må tilbys"},
        {t: "Leiebil gruppe",c: "Må tilby klasse C eller bedre"},
        {t: "Leiebil periode",c: "Må tilby leiebil i hele skadeperioden"},
      ]
    }}
    Stars4={{
      need: "For deg som har en ny bil, men kanskje ikke kjører veldig mye",
      description: 'Veldig gode bilforsikringer - 4 defaqto stjerner',
      terms: [
        {t: "Maskinskade - alder bil",c: "Må være dekket i 8 år eller mer"},
        {t: "Maskinskade - km bil",c: "Må være 200 000 km eller mer"},
        {t: "Totalskadegaranti - alder bil",c: "Må være dekket i 36 måneder"},
        {t: "Totalskadegaranti - km bil",c: "Må være 60 000 km eller mer"},
        {t: "Personlig løsøre",c: "Må være dekket for 10 000 kr"},
        {t: "Dekker tap og skade på nøkkel",c: "Må tilbys som tilleggsdekning"},
        {t: "Leiebil gruppe",c: "Må tilby klasse C eller bedre"},
        {t: "Leiebil periode",c: "Må tilby leiebil i 30 dager"},
      ]
    }}
    Stars3={{
      need: "For deg som trenger Kasko, men har en noe eldre bil",
      description: 'Gode bilforsikringer - 3 defaqto stjerner',
      terms: [
      ]
    }}
  />
)

export default IndexPage